<template>
  <b-card no-body>
    <h3 class="font-weight-bolder mt-2 text-center">{{ $t('Total Tests') }}</h3>
    <div class="text-right" style="position: absolute;right: 0;margin-top: 75px;">
      <DatePicker popup-class="popup_month_al"  type="month" v-model="selectedMonth" placeholder="" :open.sync="open" @change="changeMonth"></DatePicker>
    </div>
    <b-card-header>
      <h3 class="font-weight-bolder mt-2 text-center"></h3>
      <b-dropdown
          :text="date_filter_text"
          class="budget-dropdown dropdown-chart-al"
          variant="outline-dark"
          size="md"
        >
        <!-- <b-dropdown-item href="#" @click="changedDateRange('sevenday')">
          7 วันล่าสุด
        </b-dropdown-item> -->
        <b-dropdown-item href="#" @click="changedDateRange('montday')">
          {{ $t('Last 30 days') }}
        </b-dropdown-item>
        <b-dropdown-item href="#" @click="changedDateRange('yearago')">
          {{ $t('Year ago') }}
        </b-dropdown-item>
        <b-dropdown-item href="#" @click="openMonth">
          {{ $t('Select a month') }}
        </b-dropdown-item>
        <!-- <b-dropdown-item href="#">
          <DatePicker popup-class="popup_month_al"  type="month" v-model="selectedMonth" placeholder="เลือกเดือน" :open.sync="open" @change="changeMonth"></DatePicker>
        </b-dropdown-item> -->
        <!-- <b-dropdown-item href="#" @click="changedDateRange('twoweekday')">
          15 วันล่าสุด
        </b-dropdown-item>-->
      </b-dropdown>
    </b-card-header>   

    <b-card-body class="pb-0">
      <!-- apex chart -->
      <div v-if="chartData.length > 0">
        <vue-apex-charts
          type="line"
          height="400"
          :options="chartOptions"
          :series="formattedData"
          @dataPointSelection="pointSel"
        />
      </div>
      <div v-else class="text-center mb-3">
        <span>---- {{ $t('Data not found') }} ----</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import dateFormat from "dateformat";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import statisticsService from "@/services/statisticsService";

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BButton,
    DatePicker,
  },
  props: {
    chartData: {},
  },
  data() {
    return {
      open: false,
      date_filter_text: this.$t('Last 30 days'),
      filterDateRange: "",
      selectedMonth: "",
      title: {
        align: "center",
      },
    }
  },
  computed: {
    chartOptions() {
      return {
        xaxis: {
          type: "category",
          // labels: {
          //   formatter: function (val) {
          //     return moment(val).format("YYYY-MM-DD");
          //   },
          // },
          title: {
            text: this.$t('Time'),
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        yaxis: {},
        colors: ["#26a0fc", "#ff0000", "#00000000"],
        tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
              return (
                '<div class="px-1 py-50 text-center">' +
                "<span><span style='border-bottom: 1px solid rgba(0, 0, 0, 0.125);font-size: 10px;'>" +
                w.globals.categoryLabels[dataPointIndex] +
                '</span><br> ' +
                '<span style="font-weight: 500;font-size: 12px;margin-left: 8px;"><span class="badge bg-primary" style="background-color: #26a0fc !important;border-radius: 26px;font-size: 5px;height: 8px;width: 8px;position: absolute;left: 0;bottom: 31px;margin-left: 10px;padding: 0;">&nbsp;</span> ' +
                series[seriesIndex][dataPointIndex] +
                " ครั้ง</span> <br>" +
                '<span style="font-weight: 500;font-size: 12px;margin-left: 8px;"><span class="badge bg-primary" style="background-color: red !important;border-radius: 26px;font-size: 5px;height: 8px;width: 8px;position: absolute;left: 0;bottom: 11px;margin-left: 10px;padding: 0;">&nbsp;</span> ' +
                series[2][dataPointIndex] +
                " ครั้ง</span></span>" +
                "</div>"
              );
            }
        },
        markers: {
          size: [5, 8],
          colors: undefined,
          strokeColors: "#fff",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
      };
    },
    formattedData() {
      const stockSeries = this.chartData.map((d) => {
        return {
          x: d.format_date,
          y: [d.total_today],
          over: d.alcohol_total,
        };
      });
      const stockAL = this.chartData.map((d) => {
        return {
          x: d.format_date,
          y: [d.alcohol_format],
        };
      });
      const alcohol_total = this.chartData.map((d) => {
        return {
          x: d.format_date,
          y: [d.alcohol_total],
        };
      });
      return [
        { name: this.$t('Alcohol free') + " 0mg%", type: "line", data: stockSeries },
        { name: this.$t('Alcohol detected') + " 0mg%", type: "scatter", data: stockAL },
        { name: "", type: "none", data: alcohol_total },
      ];
    },
    
  },
  methods: {
    pointSel(chartContext, options, w) {
      let data = w.w.globals.initialSeries[0].data[w.dataPointIndex];
      let std = data.x
      let end = data.x

      if (this.date_filter_text == this.$t('Year ago')) {
        std = data.x + "-01"
        end = data.x + "-31"
      }

      if (data.over > 0) {
        this.$router.push({ name: 'report', query: { date_st: std, date_end: end, filter: 'alcohol' }});
      } else {
        //this.$router.push({ name: 'report', query: { date_st: std, date_end: end }});
      }
      
    },
    openMonth() {
      this.open = true
    },
    changeMonth() {
      this.changedDateRange('selectmonth')
    },
    changedDateRange(range){
       
      this.filterDateRange = '';
      let today = new Date();
      var endDate = dateFormat(today, "yyyy-mm-dd");

      if (range == 'sevenday'){
        this.filterDateRange = "startDate=" + moment().subtract(6,'d').format('YYYY-MM-DD') + "&endDate=" + endDate;
        this.date_filter_text = '7 วันล่าสุด'
        this.selectedMonth = ""

      } else if(range == 'twoweekday') {
        
        this.filterDateRange = "startDate=" + moment().subtract(13,'d').format('YYYY-MM-DD') + "&endDate=" + endDate;
        this.date_filter_text = '15 วันล่าสุด'
        this.selectedMonth = ""

      } else if(range == 'montday') {
        this.filterDateRange = "startDate=" + moment().subtract(30,'d').format('YYYY-MM-DD') + "&endDate=" + endDate;
        this.date_filter_text = this.$t('Last 30 days')
        this.selectedMonth = ""

      } else if(range == 'selectmonth') {
        this.filterDateRange = "startDate=" + dateFormat(this.selectedMonth, "yyyy-mm-01") + "&endDate=" + dateFormat(this.selectedMonth, "yyyy-mm-31");
        this.date_filter_text = dateFormat(this.selectedMonth, "mmm yyyy")

      } else if(range == 'yearago') {
        this.filterDateRange = "startDate=" + moment().subtract(1,'years').format('YYYY-MM') + "-01" + "&endDate=" + endDate + "&chart_type=month_year";
        this.date_filter_text = this.$t('Year ago')
        this.selectedMonth = ""
      }
      this.getChartData();
    },
    getChartData() {
      this.filterAll =
      "?" + this.filterDateRange;
      try {
        statisticsService
          .getAlcoholChart(this.filterAll)
          .then((response) => {
            this.chartData = [];
            this.chartData = response.data.chart;
          })
          .catch(() => {});
      } catch (e) {
        throw e;
      }
    },
  }
};
</script>
<style lang="scss">
.mx-input-wrapper input.mx-input {
  padding: 0;
  border: 0;
  box-shadow: unset;
}
.mx-icon-calendar {
  display: none;
}
</style>
