<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title
        >ผู้ใช้งานล่าสุด :
        <h4 class="font-midium-1 font-weight text-primary">ชื่อ : tester</h4>
      </b-card-title>

      <b-card-text class="font-small-2 mr-25 mb-0"> </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <!-- <b-col xl="3" sm="6" col="6" class="mb-2 mb-xl-0 pb-2">
         
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="danger">
                <img
                  style="width: 2.5rem; height: 2.5rem"
                  src="../../assets/images/thermometer.png"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">2</h4>
              <b-card-text class="font-small-3 mb-0"> อุณหภูมิ </b-card-text>
            </b-media-body>
          </b-media>
         
        </b-col> -->
        <b-col xl="3" sm="6" col="6" class="mb-2 mb-xl-0 pb-2">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="danger">
                <img
                  style="width: 2.5rem; height: 2.5rem"
                  src="../../assets/images/bp.png"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">2</h4>
              <b-card-text class="font-small-3 mb-0"> ความดัน </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- <b-col xl="3" sm="6" col="6" class="mb-2 mb-xl-0 pb-2">
        
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="danger">
                <img
                  style="width: 2.5rem; height: 2.5rem"
                  src="../../assets/images/oxygen.png"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">2</h4>
              <b-card-text class="font-small-3 mb-0">
                ระดับออกซิเจน
              </b-card-text>
            </b-media-body>
          </b-media>
         
        </b-col> -->
        <b-col xl="3" sm="6" col="6" class="mb-2 mb-xl-0 pb-2">
          <!-- <b-link :to="{ name: 'report', query: { filter: 'alcohol' } }"> -->
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="danger">
                <img
                  style="width: 2.5rem; height: 2.5rem"
                  src="../../assets/images/no-alcohol.png"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">2</h4>
              <b-card-text class="font-small-3 mb-0"> แอลกอฮอล์ </b-card-text>
            </b-media-body>
          </b-media>
          <!-- </b-link> -->
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
