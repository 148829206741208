<template>
  <b-card no-body>
    <h3 class="font-weight-bolder mt-2 text-center">{{ $t('Number High BP') }}</h3>
    <div class="input_month_sys text-right" style="position: absolute;right: 0;margin-top: 75px;">
      <DatePicker popup-class="popup_month_sys" type="month" v-model="selectedMonth" placeholder="" :open.sync="open" @change="changeMonth"></DatePicker>
    </div>
    <b-card-header>
      <h3 class="font-weight-bolder mt-2 text-center"></h3>
      <b-dropdown
          :text="date_filter_text"
          class="budget-dropdown dropdown-chart-sys"
          variant="outline-dark"
          size="md"
        >
        <!-- <b-dropdown-item href="#" @click="changedDateRange('sevenday')">
          7 วันล่าสุด
        </b-dropdown-item> -->
        <b-dropdown-item href="#" @click="changedDateRange('montday')">
          {{ $t('Last 30 days') }}
        </b-dropdown-item>
        <b-dropdown-item href="#" @click="changedDateRange('yearago')">
          {{ $t('Year ago') }}
        </b-dropdown-item>
        <!-- <b-dropdown-item href="#">
          <DatePicker popup-class="popup_month_sys" type="month" v-model="selectedMonth" placeholder="เลือกเดือน" :open.sync="open" @change="changeMonth"></DatePicker>
        </b-dropdown-item> -->
        <b-dropdown-item href="#" @click="openMonth">
          {{ $t('Select a month') }}
        </b-dropdown-item>
        <!-- <b-dropdown-item href="#" @click="changedDateRange('twoweekday')">
          15 วันล่าสุด
        </b-dropdown-item>
        <b-dropdown-item href="#" @click="changedDateRange('montday')">
          {{ $t('Last 30 days') }}
        </b-dropdown-item> -->
      </b-dropdown>
    </b-card-header>

    <b-card-body class="pb-0">
      <!-- apex chart -->
      <div v-if="chartSys.length > 0">
        <div id="chart">
          <apexchart
            type="line"
            height="350"
            :options="chartOptions"
            :series="formattedData"
          ></apexchart>
        </div>
      </div>
      <div v-else class="text-center mb-3">
        <span>---- {{ $t('Data not found') }} ----</span>
      </div>
      
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import dateFormat from "dateformat";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import statisticsService from "@/services/statisticsService";

export default {
  components: {
    apexchart: VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,
    DatePicker,
  },
  props: {
    chartSys: {},
  },
  data() {
    return {
      open: false,
      date_filter_text: this.$t('Last 30 days'),
      filterDateRange: "",
      selectedMonth: "",
    };
  },
  computed: {
    chartOptions() {
      return {
        title: {
          align: "center"
        },
        chart: {
          animations: {
            enabled: this.animations
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#259ffb', '#ff1100'],
        stroke: {
          width: [0, 2],
        },
        xaxis: {
        },
        yaxis: [
          {
            title: {
              text: "sys"
            },
            labels: {
              formatter: val => val,
            }
          },
        ],
        labels: this.formattedLabels
      };
    },
    formattedLabels() {
      return this.chartSys.map(d => d.format_date);
    },
    formattedData() {
      const columnSeries = this.chartSys.map(d => d.total_today);
      const lineSeries = this.chartSys.map(d => d.mv_avg_sys);
      lineSeries.forEach((element, index) => {
          if(element === 0) {
            lineSeries[index] = null;
          }
      });
      return [
        {
          name: "sys",
          type: "column",
          data: columnSeries
        },
        {
          name: "moving average",
          type: "line",
          data: lineSeries
        }
      ];
    }
  },
  created() {
  },
  watch: {
    // chartSys() {
    //   this.getbigdata();
    // },
  },
  methods: {
    openMonth() {
      this.open = true
    },
    changeMonth() {
      this.changedDateRange('selectmonth')
    },
    changedDateRange(range){
       
      this.filterDateRange = '';
      let today = new Date();
      var endDate = dateFormat(today, "yyyy-mm-dd");

      if (range == 'sevenday'){
        this.filterDateRange = "startDate=" + moment().subtract(6,'d').format('YYYY-MM-DD') + "&endDate=" + endDate;
        this.date_filter_text = '7 วันล่าสุด'
      } else if(range == 'twoweekday') {
        
        this.filterDateRange = "startDate=" + moment().subtract(13,'d').format('YYYY-MM-DD') + "&endDate=" + endDate;
        this.date_filter_text = '15 วันล่าสุด'

      } else if(range == 'montday') {
        this.filterDateRange = "startDate=" + moment().subtract(30,'d').format('YYYY-MM-DD') + "&endDate=" + endDate;
        this.date_filter_text = this.$t('Last 30 days')

      } else if(range == 'selectmonth') {
        this.filterDateRange = "startDate=" + dateFormat(this.selectedMonth, "yyyy-mm-01") + "&endDate=" + dateFormat(this.selectedMonth, "yyyy-mm-31");
        this.date_filter_text = dateFormat(this.selectedMonth, "mmm yyyy")

      } else if(range == 'yearago') {
        this.filterDateRange = "startDate=" + moment().subtract(1,'years').format('YYYY-MM') + "-01" + "&endDate=" + endDate + "&chart_type=month_year";
        this.date_filter_text = this.$t('Year ago')
        this.selectedMonth = ""
      }
      this.getChartData();
    },
    getChartData() {
      this.filterAll =
      "?" + this.filterDateRange;
      try {
        statisticsService
          .getSysAvgChart(this.filterAll)
          .then((response) => {
            this.chartSys = [];
            this.chartSys = response.data.chart_sys;
          })
          .catch(() => {});
      } catch (e) {
        throw e;
      }
    },
  }
};
</script>
<style lang="scss">
.mx-input-wrapper input.mx-input {
  padding: 0;
  border: 0;
  box-shadow: unset;
}
.mx-icon-calendar {
  display: none;
}
</style>

