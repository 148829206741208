<template>
   <b-card
    body-class="pb-50"
  >
    <h3 class="font-weight-bolder mb-1 text-center">
      {{ $t('Summary today test') }}
    </h3>
    
    <b-row style="height: 80%;">
      <b-col cols="6"  lg="4" xl="2" class="mb-75 mt-75 text-center align-self-center">
        <h5 class="mb-75 mt-75 text-center" style="height: 45px;">
          {{ $t('Number of tests') }}
        </h5>
        <b-link
          :to="{
            name: 'report',
            query: { date_st: date_st, date_end: date_end },
          }"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <h2 class="mb-1 mt-50 text-white">
              <span v-if=" bigdata.total_today > 0">{{ bigdata.total_today }}</span>
              <span v-else>-</span>
            </h2>
          </b-card>
        </b-link>
      </b-col>

      <b-col cols="6"  lg="4" xl="2" class="mb-75 mt-75 text-center align-self-center">
        <h5 class="mb-75 mt-75 text-center" style="height: 45px;">{{ $t('Alcohol found') }}</h5>
        <b-link
          :to="{
            name: 'report',
            query: { date_st: date_st, date_end: date_end, filter: 'alcohol' },
          }"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <h2 class="mb-1 mt-50 text-white">
              <span v-if=" bigdata.total_alcohol_today > 0">{{ bigdata.total_alcohol_today }}</span>
              <span v-else>-</span>
            </h2>
          </b-card>
        </b-link>
      </b-col>
      <b-col cols="6"  lg="4" xl="2" class="mb-75 mt-75 text-center align-self-center">
        <h5 class="mb-75 mt-75 text-center">{{ $t('Excessive blood pressure') }}</h5>
        <b-link
          :to="{
            name: 'report',
            query: { date_st: date_st, date_end: date_end, filter: 'sys' },
          }"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <h2 class="mb-1 mt-50 text-white">
              <span v-if=" bigdata.total_sys_today > 0">{{ bigdata.total_sys_today }}</span>
              <span v-else>-</span>
            </h2>
          </b-card>
        </b-link>
      </b-col>
      <b-col cols="6"  lg="4" xl="2" class="mb-75 mt-75 text-center align-self-center">
        <h5 class="mb-75 mt-75 text-center">{{ $t('Not speed hand') }}</h5>
        <b-link
          :to="{
            name: 'report',
            query: { date_st: date_st, date_end: date_end, filter: 'speed_hand' },
          }"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <h2 class="mb-1 mt-50 text-white">
              <span v-if=" bigdata.sph_np_today > 0">{{ bigdata.sph_np_today }}</span>
              <span v-else>-</span>
            </h2>
          </b-card>
        </b-link>
      </b-col>
      <b-col cols="6"  lg="4" xl="2" class="mb-75 mt-75 text-center align-self-center" v-if="models.indexOf('TKD') > -1">
        <h5 class="mb-75 mt-75 text-center">{{ $t('Not speed foot') }}</h5>
        <b-link
          :to="{
            name: 'report',
            query: { date_st: date_st, date_end: date_end, filter: 'speed_foot' },
          }"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <h2 class="mb-1 mt-50 text-white">
              <span v-if=" bigdata.spf_np_today > 0">{{ bigdata.spf_np_today }}</span>
              <span v-else>-</span>
            </h2>
          </b-card>
        </b-link>

      </b-col>
      <b-col cols="6"  lg="4" xl="2" class="mb-75 mt-75 text-center align-self-center">
        <h5 class="mb-75 mt-75 text-center">{{ $t('Not colorblind') }}</h5>
        <b-link
          :to="{
            name: 'report',
            query: { date_st: date_st, date_end: date_end, filter: 'blind' },
          }"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <h2 class="mb-1 mt-50 text-white">
              <span v-if=" bigdata.blind_np_today > 0">{{ bigdata.blind_np_today }}</span>
              <span v-else>-</span>
            </h2>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
        
  </b-card>
 
</template>
<script>
import {
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BProgress,
  BCardText,
  BCardHeader,
  BLink,
  BTable,
  BFormCheckbox,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BAvatar,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
// import { $themeColors } from "@themeConfig";
import { kFormatter } from "@core/utils/filter";
import jwt_decode from "jwt-decode";
import DateRangePicker from "vue2-daterange-picker";
// import reportService from "@/services/reportService.js";
// import vehiclesService from "@/services/vehiclesService.js";
// import customerService from "@/services/customerService.js";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import dateFormat from "dateformat";
// import cusDataService from "@/services/cusDataService";
import StatisticCard from "@core/components/statistics-cards/StatisticCardVertical.vue";
import statisticsService from "@/services/statisticsService";
import dateFormat from "dateformat";

export default {
  components: {
    VueApexCharts,
    StatisticCard,
    BCard,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BMedia,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BProgress,
    BCardText,
    BCardText,
    BCardHeader,
    BCardHeader,

    BLink,
    BTable,
    BFormCheckbox,

    BBadge,

    BFormGroup,
    BFormSelect,
    BPagination,
    BMediaAside,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardTitle,

    BImg,
    DateRangePicker,
  },
  directives: {
    Ripple,
  },
  // props: {
  //   bigdata: [],
  // },
  data() {
    // let today = new Date();
    // let todayEnd = new Date();
    // todayEnd.setHours(11, 59, 59, 999);

    // let startDate = new Date(
    //   today.getFullYear(),
    //   today.getMonth(),
    //   today.getDate() - 31
    // );
    // let endDate = new Date(
    //   today.getFullYear(),
    //   today.getMonth(),
    //   today.getDate(),
    //   11,
    //   59,
    //   59,
    //   999
    // );

    //let yesterdayStart = new Date();
    //yesterdayStart.setDate(today.getDate() - 7);
    // yesterdayStart.setHours(0, 0, 0, 0);

    // let yesterdayEnd = new Date();
    //yesterdayEnd.setDate(today.getDate() - 1);
    //yesterdayEnd.setHours(11, 59, 59, 999);

    return {
      // dateRange: {
      //   startDate,
      //   endDate,
      // },
      date_st: dateFormat(new Date(), "yyyy-mm-dd"),
      date_end: dateFormat(new Date(), "yyyy-mm-dd"),
    };
  },

  props: {
    bigdata: {},
    models: {},
  },
  methods: {
    kFormatter,

    // showtoken() {
    //   var token = localStorage.getItem("token");
    //   var decoded = jwt_decode(token);

    //   this.currentUserId = decoded.sub;
    //   this.role = decoded.role;
    //   if (decoded.role == "admin") {
    //     this.cusCode = null;
    //   } else {
    //     this.cusCode = decoded.cusCode;
    //   }
    // },
  },
};
</script>
<style scoped lang="scss">
.card-congratulations {
   background: #45adfc !important;
  //  border: solid 1px #126aad;

   .card-body {
    padding: 10px;
    box-shadow: 5px 5px 3px rgb(0 0 0 / 20%);
    border-radius: 7px;
   }
   .card-body:hover {
    box-shadow: unset;
   }
}  
h5.text-center {
  height: 45px !important;
}
</style>
