import http from "./http";

export default {
    getalldata(filterAll) {
        return http
            .get("quizs" + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },


    getDataBycusCode(id, filterAll) {
        return http
            .get("/answer/customer/" + id + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    

}