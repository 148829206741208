<template>
  <div>
    <b-card
      no-body
      class="card-browser-states"
    >
      <b-card-header>
        <div>
          <b-card-title>{{ $t('Maximum test score') }}
            <b-dropdown
              :text="total_text"
              size="sm"
              class="budget-dropdown"
              variant="outline-primary"
              style="z-index: 1;"
            >
              <b-dropdown-item key="5" @click="changedTotal(5)">
                TOP 5
              </b-dropdown-item>
              <b-dropdown-item key="10" @click="changedTotal(10)">
                TOP 10
              </b-dropdown-item>
              <b-dropdown-item key="20" @click="changedTotal(20)">
                TOP 20
              </b-dropdown-item>
            </b-dropdown>
          </b-card-title>
          <!-- <b-card-text class="font-small-2">
            {{startDate}} - {{endDate}}
          </b-card-text> -->
        </div>


        <div>
          <div class="text-right" style="position: absolute;right: 0;margin-top: 25px;">
            <DatePicker popup-class="popup_month_al"  type="month" v-model="selectedMonth" placeholder="" :open.sync="open" @change="changeMonth"></DatePicker>
          </div>
          <b-dropdown
            :text="date_filter_text"
            class="budget-dropdown"
            variant="outline-dark"
            left
            size="md"
          >
          <!-- <b-dropdown-item href="#" @click="changedDateRange('thisweek')">
            สัปดาห์นี้
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="changedDateRange('lastweek')">
            สัปดาห์ที่แล้ว
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="changedDateRange('thismonth')">
            เดือนนี้
          </b-dropdown-item> -->
          <b-dropdown-item href="#" @click="changedDateRange('montday')">
            {{ $t('Last 30 days') }}
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="changedDateRange('yearago')">
            {{ $t('Year ago') }}
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="openMonth">
            {{ $t('Select a month') }}
          </b-dropdown-item>
          </b-dropdown>
        </div>
        
      </b-card-header>
      
      <!-- body -->
      <b-card-body>
        <b-row class="match-height">
          <div :class="[total_model > 1 ? 'mt-2 col-md-6 col-sm-12' : 'mt-2 col-md-12 col-sm-12' ]"  v-if="models.indexOf('TKD') > -1">
            <b-card-title class="text-center">{{ $t('Question') }} Driver</b-card-title>
            <b-table
              :items="rankingData"
              responsive
              :fields="fields"
              class="mb-0"
            >
              <!-- company -->
              <template #cell(no)="data">
                <div class="text-center">
                    <b-img v-if="data.index+1 < 4"
                      :src="getImgRank(data.index+1)"
                      alt="avatar img"
                      style="max-width: 35px;position: absolute;margin-top: -3px;margin-left: -18px;"
                    />
                  <div style="position: relative;">
                    <div class="font-weight-bolder" style="font-size: 20px;">
                      {{ data.index+1 }}
                    </div>
                    <!-- <div class="font-small-2 text-muted">
                    </div> -->
                  </div> 
                
                </div>
              </template>

              <template #cell(name)="data">
                <div class="d-flex align-items-center">
                  <span v-if="data.item.firstname || data.item.lastname">{{ data.item.firstname }} {{ data.item.lastname }}</span>
                  <span v-else>---</span>
                </div>
              </template>

              <!-- views -->
              <template #cell(point)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25" v-if="data.item.point">{{ data.item.point }}</span>
                  <span class="font-weight-bolder mb-25" v-else>---</span>
                  <!-- <span class="font-small-2 text-muted text-nowrap"></span> -->
                </div>
              </template>

              <!-- revenue -->
              <template #cell(time)="data">
                <span v-if="data.item.time">{{ parseInt(data.item.time)+'s' }}</span>
                <span v-else>---</span>
              </template>
            </b-table>
          </div>
          <div :class="[total_model > 1 ? 'mt-2 col-md-6 col-sm-12' : 'mt-2 col-md-12 col-sm-12' ]" v-if="models.indexOf('TKW') > -1">
            <b-card-title class="text-center">{{ $t('Question') }} Working</b-card-title>
            <b-table
                  :items="rankingData_W"
                  responsive
                  :fields="fields"
                  class="mb-0"
                >
                  <!-- company -->
                  <template #cell(no)="data">
                    <div class="text-center">
                        <b-img v-if="data.index+1 < 4"
                          :src="getImgRank(data.index+1)"
                          alt="avatar img"
                          style="max-width: 35px;position: absolute;margin-top: -3px;margin-left: -18px;"
                        />
                      <div style="position: relative;">
                        <div class="font-weight-bolder" style="font-size: 20px;">
                          {{ data.index+1 }}
                        </div>
                        <!-- <div class="font-small-2 text-muted">
                        </div> -->
                      </div> 
                    
                    </div>
                  </template>

                  <template #cell(name)="data">
                    <div class="d-flex align-items-center">
                      <span v-if="data.item.firstname || data.item.lastname">{{ data.item.firstname }} {{ data.item.lastname }}</span>
                      <span v-else>---</span>
                    </div>
                  </template>

                  <!-- views -->
                  <template #cell(point)="data">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bolder mb-25" v-if="data.item.point">{{ data.item.point }}</span>
                      <span class="font-weight-bolder mb-25" v-else>---</span>
                      <!-- <span class="font-small-2 text-muted text-nowrap"></span> -->
                    </div>
                  </template>

                  <!-- revenue -->
                  <template #cell(time)="data">
                    <span v-if="data.item.time">{{ parseInt(data.item.time)+'s' }}</span>
                    <span v-else>---</span>
                  </template>
                </b-table>
          </div>
        </b-row>
       

      </b-card-body>
      <!--/ body -->
    </b-card>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BTable, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import answerService from "@/services/answerService";
import dateFormat from "dateformat";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
/* eslint-disable global-require */
export default {
  components: {
    BRow, 
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BTable,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    DatePicker,
  },
  props: {
    cusCode: {
    },
    models: {
    },
  },
  data() {
    let today = new Date();
    let first = today.getDate() - today.getDay()+1;
    let first_day_week = new Date(today.setDate(first));

    return {
      filterAll: '',
      filterLimit: '',
      total_text: 'TOP 5',
      open: false,
      date_filter_text: this.$t('Last 30 days'),
      selectedMonth: "",
      filterDateRange: "&startDate=" + moment().subtract(30,'d').format('YYYY-MM-DD') + "&endDate=" + dateFormat(new Date(), "yyyy-mm-dd"),
      startDate: dateFormat(first_day_week, "dd/mm/yyyy"),
      endDate: dateFormat(new Date(), "dd/mm/yyyy"),
      fields: [
        { key: 'no', label: this.$t('Rank'), class: "t-col-small text-center" },
        { key: 'name', label: this.$t('Question'), class: "t-col-name" },
        { key: 'point', label: this.$t('Score'), class: "t-col-small text-center" },
        { key: 'time', label: this.$t('Time'), class: "t-col-small text-center" },
      ],
      rankingData: [],
      rankingData_W: [],
      total_rankD: 0,
      total_rankW: 0,
      total_model: 0,
    }
  },
  async created() {
    await this.getAllData();
    await this.chkModel();
  },
  methods: {
    chkModel() {
      if (this.models.indexOf('TKD') > -1 || this.models.indexOf('TKA') > -1) { 
        this.total_model += 1;
      }
      if (this.models.indexOf('TKW') > -1) { 
        this.total_model += 1;
      }
    },
    
    getImgRank(index) {
      if (index == 1) {
        return require('@/assets/images/icons/coin_gold.png');
      } else if(index == 2) {
        return require('@/assets/images/icons/coin_silver.png');
      } else if(index == 3) {
        return require('@/assets/images/icons/coin_copper.png');
      }
    },
    changedTotal(total) {
      if (total) {
        this.filterLimit = "&total=" + total;
      } else {
        this.filterLimit = "";
      }
      this.total_text = 'TOP '+total
      this.getAllData();
    },
    openMonth() {
      this.open = true
    },
    changeMonth() {
      this.changedDateRange('selectmonth')
    },
    changedDateRange(range){
      
      this.filterDateRange = '';
      let today = new Date();
      var startDate = '';
      var endDate = dateFormat(today, "yyyy-mm-dd");
      this.endDate = dateFormat(today, "dd/mm/yyyy");
     
      if (range == 'thisweek'){
        this.filterDateRange = '';
        this.date_filter_text = 'สัปดาห์นี้'
      } else if(range == 'lastweek') {
         
        let yesterdayEnd = new Date();
        yesterdayEnd.setDate(today.getDate() - 1);
 
        let first = today.getDate() - today.getDay();    
    
        let endDate = new Date(today.setDate(first));
  
        let lastweek_st = new Date();
        startDate = lastweek_st.setDate(endDate.getDate() - 6);

        this.filterDateRange = "&startDate=" + dateFormat(startDate, "yyyy-mm-dd") + "&endDate=" + dateFormat(endDate, "yyyy-mm-dd");
        this.startDate = dateFormat(startDate, "dd/mm/yyyy");
        this.endDate = dateFormat(endDate, "dd/mm/yyyy");
        this.date_filter_text = 'สัปดาห์ที่แล้ว'

      } else if(range == 'thismonth') {
        let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        this.filterDateRange = "&startDate=" + dateFormat(thisMonthStart, "yyyy-mm-dd") + "&endDate=" + endDate;
        this.startDate = dateFormat(thisMonthStart, "dd/mm/yyyy");
        this.date_filter_text = 'เดือนนี้'

      } else if(range == 'montday') {
        this.filterDateRange = "&startDate=" + moment().subtract(30,'d').format('YYYY-MM-DD') + "&endDate=" + endDate;
        this.date_filter_text = this.$t('Last 30 days')
        this.selectedMonth = ""

      } else if(range == 'selectmonth') {
        this.filterDateRange = "&startDate=" + dateFormat(this.selectedMonth, "yyyy-mm-01") + "&endDate=" + dateFormat(this.selectedMonth, "yyyy-mm-31");
        this.date_filter_text = dateFormat(this.selectedMonth, "mmm yyyy")

      } else if(range == 'yearago') {
        this.filterDateRange = "&startDate=" + moment().subtract(1,'years').format('YYYY-MM') + "-01" + "&endDate=" + endDate + "&chart_type=month_year";
        this.date_filter_text = this.$t('Year ago')
        this.selectedMonth = ""
      }

      this.getAllData();
    },
    getAllData() {
      this.filterAll =
        "?page=1" +
        this.filterLimit +
        this.filterDateRange;

      try {
        answerService
          .getDataBycusCode(this.cusCode, this.filterAll)
          .then((response) => {
            this.rankingData = response.data.data;
            this.total_rankD = this.rankingData.length;
            this.rankingData_W = response.data.data_w;
            this.total_rankW = this.rankingData_W.length;
            
            if (response.data.data.length < 5) {
              let row_empty = 5-response.data.data.length
              for (let i = 0; i < row_empty; i++) {
                this.rankingData.push({
                  id: 0,
                  firstname:'',
                  lastname:'',
                  point:'',
                  time:'',
                })
              }
            }

            if (this.rankingData_W.length < 5) {
              let row_empty = 5-this.rankingData_W.length
              for (let i = 0; i < row_empty; i++) {
                this.rankingData_W.push({
                  id: 0,
                  firstname:'',
                  lastname:'',
                  point:'',
                  time:'',
                })
              }
            }
          })
          .catch(() => {});

        // this.data1 = response.data;
        // console.log(this.User_data);
      } catch (e) {
        // console.log(e);
      }
    },
  }
}
</script>

<style lang="scss">
  .t-col-small {
    min-width: 100px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .t-col-name {
    min-width: 200px !important;
  }
  .mx-input-wrapper input.mx-input {
    padding: 0;
    border: 0;
    box-shadow: unset;
    color: transparent;
  }
  .mx-icon-calendar {
    display: none;
  }
</style>
  
