var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"body-class":"pb-50"}},[_c('h3',{staticClass:"font-weight-bolder mb-1 text-center"},[_vm._v(" "+_vm._s(_vm.$t('Test summary'))+" "+_vm._s(_vm.date_filter_text)+" ")]),_c('div',{staticClass:"text-right",staticStyle:{"position":"absolute","right":"0","margin-top":"25px"}},[_c('DatePicker',{attrs:{"popup-class":"popup_month_al","type":"month","placeholder":"","open":_vm.open},on:{"update:open":function($event){_vm.open=$event},"change":_vm.changeMonth},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}})],1),_c('b-card-header',{staticClass:"pb-0"},[_c('h3',{staticClass:"font-weight-bolder mt-2 text-center"}),_c('b-dropdown',{staticClass:"budget-dropdown",attrs:{"text":_vm.date_filter_text,"variant":"outline-dark","size":"md"}},[_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.changedDateRange('montday')}}},[_vm._v(" "+_vm._s(_vm.$t('Last 30 days'))+" ")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.changedDateRange('yearago')}}},[_vm._v(" "+_vm._s(_vm.$t('Year ago'))+" ")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":_vm.openMonth}},[_vm._v(" "+_vm._s(_vm.$t('Select a month'))+" ")])],1)],1),_c('b-row',{staticStyle:{"height":"80%"},attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"mb-75 mt-75 text-center align-self-center",attrs:{"cols":"6","lg":"4","xl":"2"}},[_c('h5',{staticClass:"mb-75 mt-75 text-center",staticStyle:{"height":"45px"}},[_vm._v(" "+_vm._s(_vm.$t('Number of tests'))+" ")]),_c('b-link',{attrs:{"to":{
            name: 'report',
            query: { date_st: _vm.date_st, date_end: _vm.date_end },
          }}},[_c('b-card',{staticClass:"card card-congratulations",attrs:{"text-variant":"center"}},[_c('h2',{staticClass:"mb-1 mt-50 text-white"},[( _vm.showData.total_month > 0)?_c('span',[_vm._v(_vm._s(_vm.showData.total_month))]):_c('span',[_vm._v("-")])])])],1)],1),_c('b-col',{staticClass:"mb-75 mt-75 text-center align-self-center",attrs:{"cols":"6","lg":"4","xl":"2"}},[_c('h5',{staticClass:"mb-75 mt-75 text-center",staticStyle:{"height":"45px"}},[_vm._v(_vm._s(_vm.$t('Alcohol found')))]),_c('b-link',{attrs:{"to":{
            name: 'report',
            query: { date_st: _vm.date_st, date_end: _vm.date_end, filter: 'alcohol' },
          }}},[_c('b-card',{staticClass:"card card-congratulations",attrs:{"text-variant":"center"}},[_c('h2',{staticClass:"mb-1 mt-50 text-white"},[( _vm.showData.total_alcohol_month > 0)?_c('span',[_vm._v(_vm._s(_vm.showData.total_alcohol_month))]):_c('span',[_vm._v("-")])])])],1)],1),_c('b-col',{staticClass:"mb-75 mt-75 text-center align-self-center",attrs:{"cols":"6","lg":"4","xl":"2"}},[_c('h5',{staticClass:"mb-75 mt-75 text-center"},[_vm._v(_vm._s(_vm.$t('Excessive blood pressure')))]),_c('b-link',{attrs:{"to":{
            name: 'report',
            query: { date_st: _vm.date_st, date_end: _vm.date_end, filter: 'sys' },
          }}},[_c('b-card',{staticClass:"card card-congratulations",attrs:{"text-variant":"center"}},[_c('h2',{staticClass:"mb-1 mt-50 text-white"},[( _vm.showData.total_sys_month > 0)?_c('span',[_vm._v(_vm._s(_vm.showData.total_sys_month))]):_c('span',[_vm._v("-")])])])],1)],1),_c('b-col',{staticClass:"mb-75 mt-75 text-center align-self-center",attrs:{"cols":"6","lg":"4","xl":"2"}},[_c('h5',{staticClass:"mb-75 mt-75 text-center"},[_vm._v(_vm._s(_vm.$t('Not speed hand')))]),_c('b-link',{attrs:{"to":{
            name: 'report',
            query: { date_st: _vm.date_st, date_end: _vm.date_end, filter: 'speed_hand' },
          }}},[_c('b-card',{staticClass:"card card-congratulations",attrs:{"text-variant":"center"}},[_c('h2',{staticClass:"mb-1 mt-50 text-white"},[( _vm.showData.sph_np_month > 0)?_c('span',[_vm._v(_vm._s(_vm.showData.sph_np_month))]):_c('span',[_vm._v("-")])])])],1)],1),(_vm.models.indexOf('TKD') > -1)?_c('b-col',{staticClass:"mb-75 mt-75 text-center align-self-center",attrs:{"cols":"6","lg":"4","xl":"2"}},[_c('h5',{staticClass:"mb-75 mt-75 text-center"},[_vm._v(_vm._s(_vm.$t('Not speed foot')))]),_c('b-link',{attrs:{"to":{
            name: 'report',
            query: { date_st: _vm.date_st, date_end: _vm.date_end, filter: 'speed_foot' },
          }}},[_c('b-card',{staticClass:"card card-congratulations",attrs:{"text-variant":"center"}},[_c('h2',{staticClass:"mb-1 mt-50 text-white"},[( _vm.showData.spf_np_month > 0)?_c('span',[_vm._v(_vm._s(_vm.showData.spf_np_month))]):_c('span',[_vm._v("-")])])])],1)],1):_vm._e(),_c('b-col',{staticClass:"mb-75 mt-75 text-center align-self-center",attrs:{"cols":"6","lg":"4","xl":"2"}},[_c('h5',{staticClass:"mb-75 mt-75 text-center"},[_vm._v(_vm._s(_vm.$t('Not colorblind')))]),_c('b-link',{attrs:{"to":{
            name: 'report',
            query: { date_st: _vm.date_st, date_end: _vm.date_end, filter: 'blind' },
          }}},[_c('b-card',{staticClass:"card card-congratulations",attrs:{"text-variant":"center"}},[_c('h2',{staticClass:"mb-1 mt-50 text-white"},[( _vm.showData.blind_np_month > 0)?_c('span',[_vm._v(_vm._s(_vm.showData.blind_np_month))]):_c('span',[_vm._v("-")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }