<template>

<b-card
    body-class="pb-50"
  >
    <h3 class="font-weight-bolder mb-1 text-center">
      {{ $t('Test summary') }} {{ date_filter_text }}
    </h3>
    <div class="text-right" style="position: absolute;right: 0;margin-top: 25px;">
      <DatePicker popup-class="popup_month_al"  type="month" v-model="selectedMonth" placeholder="" :open.sync="open" @change="changeMonth"></DatePicker>
    </div>
    <b-card-header class="pb-0">
      <h3 class="font-weight-bolder mt-2 text-center"></h3>
      <b-dropdown
          :text="date_filter_text"
          class="budget-dropdown"
          variant="outline-dark"
          size="md"
        >
        <b-dropdown-item href="#" @click="changedDateRange('montday')">
          {{ $t('Last 30 days') }}
        </b-dropdown-item>
        <b-dropdown-item href="#" @click="changedDateRange('yearago')">
          {{ $t('Year ago') }}
        </b-dropdown-item>
        <b-dropdown-item href="#" @click="openMonth">
          {{ $t('Select a month') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
     
    <b-row align-v="center" style="height: 80%;">
      <b-col cols="6"  lg="4" xl="2" class="mb-75 mt-75 text-center align-self-center">
        <h5 class="mb-75 mt-75 text-center" style="height: 45px;">
          {{ $t('Number of tests') }}
        </h5>
        <b-link
          :to="{
            name: 'report',
            query: { date_st: date_st, date_end: date_end },
          }"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <h2 class="mb-1 mt-50 text-white">
              <span v-if=" showData.total_month > 0">{{ showData.total_month }}</span>
              <span v-else>-</span>
            </h2>
          </b-card>
        </b-link>
      </b-col>

      <b-col cols="6"  lg="4" xl="2" class="mb-75 mt-75 text-center align-self-center">
        <h5 class="mb-75 mt-75 text-center" style="height: 45px;">{{ $t('Alcohol found') }}</h5>
        <b-link
          :to="{
            name: 'report',
            query: { date_st: date_st, date_end: date_end, filter: 'alcohol' },
          }"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <h2 class="mb-1 mt-50 text-white">
              <span v-if=" showData.total_alcohol_month > 0">{{ showData.total_alcohol_month }}</span>
              <span v-else>-</span>
            </h2>
          </b-card>
        </b-link>
      </b-col>
      <b-col cols="6"  lg="4" xl="2" class="mb-75 mt-75 text-center align-self-center">
        <h5 class="mb-75 mt-75 text-center">{{ $t('Excessive blood pressure') }}</h5>
        <b-link
          :to="{
            name: 'report',
            query: { date_st: date_st, date_end: date_end, filter: 'sys' },
          }"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <h2 class="mb-1 mt-50 text-white">
              <span v-if=" showData.total_sys_month > 0">{{ showData.total_sys_month }}</span>
              <span v-else>-</span>
            </h2>
          </b-card>
        </b-link>
      </b-col>
      <b-col cols="6"  lg="4" xl="2" class="mb-75 mt-75 text-center align-self-center">
        <h5 class="mb-75 mt-75 text-center">{{ $t('Not speed hand') }}</h5>
        <b-link
          :to="{
            name: 'report',
            query: { date_st: date_st, date_end: date_end, filter: 'speed_hand' },
          }"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <h2 class="mb-1 mt-50 text-white">
              <span v-if=" showData.sph_np_month > 0">{{ showData.sph_np_month }}</span>
              <span v-else>-</span>
            </h2>
          </b-card>
        </b-link>
      </b-col>
      <b-col cols="6"  lg="4" xl="2" class="mb-75 mt-75 text-center align-self-center" v-if="models.indexOf('TKD') > -1">
        <h5 class="mb-75 mt-75 text-center">{{ $t('Not speed foot') }}</h5>
        <b-link
          :to="{
            name: 'report',
            query: { date_st: date_st, date_end: date_end, filter: 'speed_foot' },
          }"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <h2 class="mb-1 mt-50 text-white">
              <span v-if=" showData.spf_np_month > 0">{{ showData.spf_np_month }}</span>
              <span v-else>-</span>
            </h2>
          </b-card>
        </b-link>

      </b-col>
      <b-col cols="6"  lg="4" xl="2" class="mb-75 mt-75 text-center align-self-center">
        <h5 class="mb-75 mt-75 text-center">{{ $t('Not colorblind') }}</h5>
        <b-link
          :to="{
            name: 'report',
            query: { date_st: date_st, date_end: date_end, filter: 'blind' },
          }"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <h2 class="mb-1 mt-50 text-white">
              <span v-if=" showData.blind_np_month > 0">{{ showData.blind_np_month }}</span>
              <span v-else>-</span>
            </h2>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
        
  </b-card>
</template>
<script>
import {
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BProgress,
  BCardText,
  BCardHeader,
  BLink,
  BTable,
  BFormCheckbox,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BAvatar,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
// import { $themeColors } from "@themeConfig";
import { kFormatter } from "@core/utils/filter";
import jwt_decode from "jwt-decode";
import DateRangePicker from "vue2-daterange-picker";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import StatisticCard from "@core/components/statistics-cards/StatisticCardVertical.vue";
import statisticsService from "@/services/statisticsService";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import dateFormat from "dateformat";
import moment from "moment";

export default {
  components: {
    VueApexCharts,
    StatisticCard,
    BCard,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BMedia,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BProgress,
    BCardText,
    BCardText,
    BCardHeader,
    BCardHeader,

    BLink,
    BTable,
    BFormCheckbox,

    BBadge,

    BFormGroup,
    BFormSelect,
    BPagination,
    BMediaAside,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardTitle,

    BImg,
    DateRangePicker,
    DatePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    let today = new Date();
    let first = today.getDate() - today.getDay() + 1;

    var endDate = dateFormat(today);
    let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);

    return {
      open: false,
      date_filter_text: this.$t('Last 30 days'),
      selectedMonth: "",
      filterDateRange: "",
      date_st: moment().subtract(30,'d').format('YYYY-MM-DD'), 
      date_end: dateFormat(endDate, "yyyy-mm-dd"),
      showData: [],
      // dateRange: {
      //   startDate,
      //   endDate,
      // },
    };
  },

  props: {
    bigdata: {},
    models: {},
  },
  watch: {
    bigdata() {
      this.showData = this.bigdata;
    },
  },
  methods: {
    kFormatter,
    openMonth() {
      this.open = true
    },
    changeMonth() {
      this.changedDateRange('selectmonth')
    },
    changedDateRange(range){
       
       this.filterDateRange = '';
       let today = new Date();
       var endDate = dateFormat(today, "yyyy-mm-dd");
 
       if (range == 'sevenday'){
         this.filterDateRange = "startDate=" + moment().subtract(6,'d').format('YYYY-MM-DD') + "&endDate=" + endDate;
         this.date_filter_text = '7 วันล่าสุด'
         this.selectedMonth = ""
 
       } else if(range == 'twoweekday') {
         
         this.filterDateRange = "startDate=" + moment().subtract(13,'d').format('YYYY-MM-DD') + "&endDate=" + endDate;
         this.date_filter_text = '15 วันล่าสุด'
         this.selectedMonth = ""
 
       } else if(range == 'montday') {
          this.date_st = moment().subtract(30,'d').format('YYYY-MM-DD')
          this.date_end = endDate
          this.filterDateRange = "startDate=" + this.date_st + "&endDate=" + this.date_end;
          this.date_filter_text = this.$t('Last 30 days')
          this.selectedMonth = ""

       } else if(range == 'selectmonth') {
          this.date_st = dateFormat(this.selectedMonth, "yyyy-mm-01")
          this.date_end = dateFormat(this.selectedMonth, "yyyy-mm-31")
          this.filterDateRange = "startDate=" + this.date_st + "&endDate=" + this.date_end;
          this.date_filter_text = dateFormat(this.selectedMonth, "mmm yyyy")
 
       } else if(range == 'yearago') {
          this.date_st = moment().subtract(1,'years').format('YYYY-MM') + "-01"
          this.date_end = endDate
          this.filterDateRange = "startDate=" + this.date_st + "&endDate=" + this.date_end + "&chart_type=month_year";
          this.date_filter_text = this.$t('Year ago')
          this.selectedMonth = ""
       }
       this.getAllData();
     },
     getAllData() {
      let filterAll =
      "?" + this.filterDateRange;

      try {
        statisticsService
          .getDataTestTotal(filterAll)
          .then((response) => {
            this.showData = [];
            this.showData = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        throw e;
      }
    },
  },
  async created() {
    //await this.showtoken();
    //await this.getData();
  },
};
</script>
<style scoped lang="scss">
.card-congratulations {
   background: #45adfc !important;
  //  border: solid 1px #126aad;

   .card-body {
    padding: 10px;
    box-shadow: 5px 5px 3px rgb(0 0 0 / 20%);
    border-radius: 7px;
   }
   .card-body:hover {
    box-shadow: unset;
   }
}  
h5.text-center {
  height: 45px !important;
}
</style>
<style lang="scss">
.mx-input-wrapper input.mx-input {
  padding: 0;
  border: 0;
  box-shadow: unset;
  color: transparent;
}
.mx-icon-calendar {
  display: none;
}
</style>
