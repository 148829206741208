import http from "./http";

export default {




    getDataBycusCode() {
        return http
            .get("/statistics/customer")
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    getDataTestTotal(filterAll) {
        return http
            .get("/statistics/test-total"+ filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    getAlcoholChart(filterAll) {
        return http
            .get("/statistics/chart-alcohol" + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    getSysAvgChart(filterAll) {
        return http
            .get("/statistics/sys-moving-avg" + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },


}