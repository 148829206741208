<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col lg="6">
        <chart-data :chartData="chartData" />
      </b-col>
      <b-col lg="6">
        <total-today :bigdata="bigdata" :models="models"/>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <chart-sys :chartSys="chartSys" />
      </b-col>
      <b-col lg="6">
        <total-month :bigdata="bigdata" :models="models"/>
      </b-col>
    </b-row>

    <!-- <b-row class="match-height">
      <b-col xl="4" md="4" sm="6"> </b-col>
      <b-col xl="4" md="4" sm="6">
        <total-week :total_week="bigdata.total_week" />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <total-month :total_month="bigdata.total_month" />
      </b-col>
    </b-row> -->
    <b-row>
      <!-- <b-col lg="3" sm="12">
        <max-alhohol :total_alcohol="bigdata.total_alcohol" />
      </b-col>
      <b-col lg="3" sm="12">
        <max-temp :total_sys="bigdata.total_sys" />
      </b-col> -->
    <div v-if="chkModelOption('quiz')" style="width: 100%;">
        <b-col v-if="chkModel() > 1" lg="12" sm="12">
          <ranking-score :cusCode="cusCode" :models="models"/>
        </b-col>
        <b-col lg="6" sm="12" v-else>
          <ranking-score :cusCode="cusCode" :models="models"/>
        </b-col>
    </div>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol,  BOverlay, } from "bootstrap-vue";

import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import UserNow from "./UserNow.vue";
import TotalToday from "./TotalToday.vue";
import TotalWeek from "./TotalWeek.vue";
import TotalMonth from "./TotalMonth.vue";
import MaxAlhohol from "./MaxAlhohol.vue";
import ChartData from "./ChartData.vue";
import ChartSys from "./ChartSys.vue";
import MaxTemp from "./MaxTemp.vue";
import RankingScore from "./RankingScore.vue";
import jwt_decode from "jwt-decode";
import { formatDate, kFormatter } from "@core/utils/filter";
import statisticsService from "@/services/statisticsService";

export default {
  components: {
    BRow,
    BCol,
    BOverlay,

    StatisticCardWithAreaChart,
    UserNow,
    TotalMonth,
    TotalWeek,
    TotalToday,
    MaxTemp,
    MaxAlhohol,
    RankingScore,
    ChartData,
    ChartSys,
  },
  data() {
    return {
      chartData: [],
      bigdata: {},
      chartSys: [],
      models: [],
      modelOption: [],
      total_today: "",
      total_week: "",
      total_month: "",
      total_alcohol: "",
      total_sys: "",
      format_date: "",
      total_week_test: "",
      total_today_test: "",

      total_alcohol_today: "",
      blind_np_today: "",
      spf_np_today: "",
      sph_np_today: "",
      total_sys_today: "",

      total_sys_month: "",
      total_alcohol_month: "",
      spf_np_month: "",
      sph_np_month: "",

      blind_np_month: "",
      data: {
        cusCode: null,
        role: "",
      },
    };
  },
  async created() {
    await this.showtoken();
    await this.getbigdata();
  },
  methods: {
    chkModel() {
      let model_count = 0;
      if (this.models.indexOf('TKD') > -1 || this.models.indexOf('TKA') > -1) { 
        model_count += 1;
      }
      if (this.models.indexOf('TKW') > -1) { 
        model_count += 1;
      }
      return model_count;
    },
    chkModelOption(val) {
      var res = false
      if (this.modelOption) { 
        let position = this.modelOption.indexOf(val);
        if (position > 0){
          res = true
        }
      } else {
        res = true
      }
      return res;
    },
    getbigdata() {
      // this.filterDateRange();

      try {
        statisticsService
          .getDataBycusCode()
          .then((response) => {
            this.bigdata = response.data.data;
            //console.log(response.data.data);
            console.log(this.bigdata);
            this.chartData = [];
            this.chartData = response.data.chart;
            console.log(this.chartData);
            //this.bigdata.total_today = [];

            // chartData.forEach((item) => {
            //   if (item.total_today > 0) {
            //     let v = {
            //       x: item.format_date,
            //       y: item.total_today,
            //     };
            //     this.chartData.push(v);
            //   }
            // });
            this.chartSys = response.data.chart_sys;
            this.models = response.data.models
            this.modelOption = response.data.modelOptions
            //this.totalwk = response.data.data.totalwk;
            //this.totalmonth = response.data.data.totalmonth;
            //console.log(this.bigdata);
            //this.totalRows = response.data.rows[0].total;
          })
          .catch(() => {});
      } catch (e) {
        throw e;
      }
    },
    // getChartdata() {
    //   console.log(this.$route.params.id);

    //   reportService
    //     .getdata(this.$route.params.id)
    //     .then((response) => {
    //       let chartData = response.data.data;

    //       this.diaData = [];

    //       this.pushScoreData = [];
    //       chartData.forEach((item) => {
    //         if (item.dia > 0) {
    //           let v = { x: item.created_at, y: item.dia };
    //           this.diaData.push(v);
    //         }
    //       });
    //     })
    //     .catch(() => {});
    // },
    kFormatter,
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      this.role = decoded.role;

      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = null;
      } else {
        this.cusCode = decoded.cusCode;
      }
    },
  },

  async created() {
    this.showtoken();
    // this.getvehicledata();

    await this.getbigdata();
  },
  refetchData() {
    this.getallcustomer();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
