<template>
  <b-row class="match-height">
    <b-col>
      <b-link
        :to="{
          name: 'report',
          query: { date_st: date_st, date_end: date_end, filter: 'sys' },
        }"
      >
        <b-card>
          <b-card-header>
            <h1 class="font-midium-1 font-weight-bolder text-primary">
              {{ Number(total_sys).toLocaleString() }} คน
            </h1>

            <b-col lg="3" sm="6">
              <b-avatar rounded="circle" size="50" variant="white">
                <b-img
                  :src="require('@/assets/images/blood_pressure.png')"
                  style="width: 100%"
                ></b-img></b-avatar></b-col
          ></b-card-header>
          <b-card-body><h4>จำนวนผู้ใช้มีความดัน (เดือนนี้)</h4></b-card-body>
        </b-card>
      </b-link>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BProgress,
  BCardText,
  BCardHeader,
  BLink,
  BTable,
  BFormCheckbox,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BAvatar,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
// import { $themeColors } from "@themeConfig";
import { kFormatter } from "@core/utils/filter";
import jwt_decode from "jwt-decode";
import DateRangePicker from "vue2-daterange-picker";
// import reportService from "@/services/reportService.js";
// import vehiclesService from "@/services/vehiclesService.js";
// import customerService from "@/services/customerService.js";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import dateFormat from "dateformat";
// import cusDataService from "@/services/cusDataService";
import StatisticCard from "@core/components/statistics-cards/StatisticCardVertical.vue";
import statisticsService from "@/services/statisticsService";
import dateFormat from "dateformat";
export default {
  components: {
    VueApexCharts,
    StatisticCard,
    BCard,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BMedia,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BProgress,
    BCardText,
    BCardText,
    BCardHeader,
    BCardHeader,

    BLink,
    BTable,
    BFormCheckbox,

    BBadge,

    BFormGroup,
    BFormSelect,
    BPagination,
    BMediaAside,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardTitle,

    BImg,
    DateRangePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    let today = new Date();
    let first = today.getDate() - today.getDay() + 1;

    var endDate = dateFormat(today);
    let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);

    return {
      date_st: dateFormat(thisMonthStart, "yyyy-mm-dd"),
      date_end: dateFormat(endDate, "yyyy-mm-dd"),
      // dateRange: {
      //   startDate,
      //   endDate,
      // },
    };
  },

  props: {
    total_sys: {},
  },
  methods: {
    kFormatter,
  },
  async created() {
    //this.showtoken();
    // this.getvehicledata();
    // await this.getbigdata();
  },
  // refetchData() {
  //   this.getallcustomer();
  // },
};
</script>
