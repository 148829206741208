var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"body-class":"pb-50"}},[_c('h3',{staticClass:"font-weight-bolder mb-1 text-center"},[_vm._v(" "+_vm._s(_vm.$t('Summary today test'))+" ")]),_c('b-row',{staticStyle:{"height":"80%"}},[_c('b-col',{staticClass:"mb-75 mt-75 text-center align-self-center",attrs:{"cols":"6","lg":"4","xl":"2"}},[_c('h5',{staticClass:"mb-75 mt-75 text-center",staticStyle:{"height":"45px"}},[_vm._v(" "+_vm._s(_vm.$t('Number of tests'))+" ")]),_c('b-link',{attrs:{"to":{
          name: 'report',
          query: { date_st: _vm.date_st, date_end: _vm.date_end },
        }}},[_c('b-card',{staticClass:"card card-congratulations",attrs:{"text-variant":"center"}},[_c('h2',{staticClass:"mb-1 mt-50 text-white"},[( _vm.bigdata.total_today > 0)?_c('span',[_vm._v(_vm._s(_vm.bigdata.total_today))]):_c('span',[_vm._v("-")])])])],1)],1),_c('b-col',{staticClass:"mb-75 mt-75 text-center align-self-center",attrs:{"cols":"6","lg":"4","xl":"2"}},[_c('h5',{staticClass:"mb-75 mt-75 text-center",staticStyle:{"height":"45px"}},[_vm._v(_vm._s(_vm.$t('Alcohol found')))]),_c('b-link',{attrs:{"to":{
          name: 'report',
          query: { date_st: _vm.date_st, date_end: _vm.date_end, filter: 'alcohol' },
        }}},[_c('b-card',{staticClass:"card card-congratulations",attrs:{"text-variant":"center"}},[_c('h2',{staticClass:"mb-1 mt-50 text-white"},[( _vm.bigdata.total_alcohol_today > 0)?_c('span',[_vm._v(_vm._s(_vm.bigdata.total_alcohol_today))]):_c('span',[_vm._v("-")])])])],1)],1),_c('b-col',{staticClass:"mb-75 mt-75 text-center align-self-center",attrs:{"cols":"6","lg":"4","xl":"2"}},[_c('h5',{staticClass:"mb-75 mt-75 text-center"},[_vm._v(_vm._s(_vm.$t('Excessive blood pressure')))]),_c('b-link',{attrs:{"to":{
          name: 'report',
          query: { date_st: _vm.date_st, date_end: _vm.date_end, filter: 'sys' },
        }}},[_c('b-card',{staticClass:"card card-congratulations",attrs:{"text-variant":"center"}},[_c('h2',{staticClass:"mb-1 mt-50 text-white"},[( _vm.bigdata.total_sys_today > 0)?_c('span',[_vm._v(_vm._s(_vm.bigdata.total_sys_today))]):_c('span',[_vm._v("-")])])])],1)],1),_c('b-col',{staticClass:"mb-75 mt-75 text-center align-self-center",attrs:{"cols":"6","lg":"4","xl":"2"}},[_c('h5',{staticClass:"mb-75 mt-75 text-center"},[_vm._v(_vm._s(_vm.$t('Not speed hand')))]),_c('b-link',{attrs:{"to":{
          name: 'report',
          query: { date_st: _vm.date_st, date_end: _vm.date_end, filter: 'speed_hand' },
        }}},[_c('b-card',{staticClass:"card card-congratulations",attrs:{"text-variant":"center"}},[_c('h2',{staticClass:"mb-1 mt-50 text-white"},[( _vm.bigdata.sph_np_today > 0)?_c('span',[_vm._v(_vm._s(_vm.bigdata.sph_np_today))]):_c('span',[_vm._v("-")])])])],1)],1),(_vm.models.indexOf('TKD') > -1)?_c('b-col',{staticClass:"mb-75 mt-75 text-center align-self-center",attrs:{"cols":"6","lg":"4","xl":"2"}},[_c('h5',{staticClass:"mb-75 mt-75 text-center"},[_vm._v(_vm._s(_vm.$t('Not speed foot')))]),_c('b-link',{attrs:{"to":{
          name: 'report',
          query: { date_st: _vm.date_st, date_end: _vm.date_end, filter: 'speed_foot' },
        }}},[_c('b-card',{staticClass:"card card-congratulations",attrs:{"text-variant":"center"}},[_c('h2',{staticClass:"mb-1 mt-50 text-white"},[( _vm.bigdata.spf_np_today > 0)?_c('span',[_vm._v(_vm._s(_vm.bigdata.spf_np_today))]):_c('span',[_vm._v("-")])])])],1)],1):_vm._e(),_c('b-col',{staticClass:"mb-75 mt-75 text-center align-self-center",attrs:{"cols":"6","lg":"4","xl":"2"}},[_c('h5',{staticClass:"mb-75 mt-75 text-center"},[_vm._v(_vm._s(_vm.$t('Not colorblind')))]),_c('b-link',{attrs:{"to":{
          name: 'report',
          query: { date_st: _vm.date_st, date_end: _vm.date_end, filter: 'blind' },
        }}},[_c('b-card',{staticClass:"card card-congratulations",attrs:{"text-variant":"center"}},[_c('h2',{staticClass:"mb-1 mt-50 text-white"},[( _vm.bigdata.blind_np_today > 0)?_c('span',[_vm._v(_vm._s(_vm.bigdata.blind_np_today))]):_c('span',[_vm._v("-")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }